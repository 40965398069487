<template>
  <div class="vg_wrapper">
    <el-card v-loading='isLoading'>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getPconsNow()">
          <el-row>
            <el-col :md="6">
              <el-form-item label="销售编号：">
                <el-input size="small" v-model.trim="searchForm.pcon_no" clearable placeholder="请填写产品货号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="单据状态：">
                <selectStatus @selectStatusRow="getStatusVal"></selectStatus>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="交货时间：">
                <el-date-picker
                  size="small"
                  v-model="searchForm.timeValue1"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="14">
              <el-form-item label="录入时间：">
                  <el-date-picker
                      size="small"
                      v-model="searchForm.timeValue"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getPconsNow()" class="vg_ml_16">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button> -->
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_pointer" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border>
            <el-table-column type="selection" width="48"  align="center"/>
            <el-table-column label="销售编号" prop="pcon_no" />
            <el-table-column label="公司抬头" prop="acct_fctr_name" />
            <el-table-column label="合同类型" prop="pcon_type" :formatter="formatType"/>
            <el-table-column label="采购单位" prop="acct_fctr_name" />
            <el-table-column label="销售单位" prop="tg_acct_fctr_name" />
            <el-table-column label="总价格" prop="pcon_total" />
            <el-table-column label="交货时间" prop="pcon_date" :formatter="formatDate1" />
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column label="单据状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {pconAPI} from "@api/modules/pcon";
import {cpttAPI} from "@api/modules/comptitle";
import selectStatus from "@/views/component/selectStatus";
import pubPagination from "@/components/common/pubPagination";
export default {
name: "pconList",
  components: {
    pubPagination,
    selectStatus
  },
  data() {
    return {
      searchForm: {
        pcon_no: '',
        cptt_id:'',
        status:'',
        timeValue:{
          startTime:null,
          endTime:null
        },
        timeValue1:{
          startTime:null,
          endTime:null
        }
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      pconTypeGroupOpt:[],
      btn:{},
      cpptForm:[],
      isLoading:true
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/pcon_add' || from.path === '/pcon_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.isLoading = true 
      this.getPconsList();
      this.getCppt()
    },
    // 获取样品信息
    getPconsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      const timeNewVal1 = this.helper.getTime(this.searchForm.timeValue1)
      get(pconAPI.getPcons,
        {
          pcon_no: this.searchForm.pcon_no,
          cptt_id: this.searchForm.cptt_id,
          status: this.searchForm.status,
          start_time: timeNewVal1.startTime,
          end_time: timeNewVal1.endTime,
          start_time2: timeNewVal.startTime,
          end_time2: timeNewVal.endTime,
          page_no: this.currentPage,
        })
        .then(res => {
          if(res.data.code === 0){
            console.log('res',res)
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
          setTimeout(()=>{
            this.isLoading = false 
          },500)
        })
        .catch(res=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        })
    },
    // 获取供应商信息
    getCppt(){
      get(cpttAPI.getAllCpttsV1)
      .then(res=>{
        if (res.data.code === 0) {
          this.cpptForm = res.data.data;
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        pcon_no: '',
        cptt_id:'',
        status:'',
        timeValue:{
          startTime:null,
          endTime:null
        },
        timeValue1:{
          startTime:null,
          endTime:null
        }
      };
      this.currentPage = 1
      this.initData()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    formatDate1(row) {
      return this.helper.toStringDate(row.pcon_date)
    },
    // 类型转换
    formatType(row){
      if(row.pcon_type == 0){
        return '采购合同'
      }else if(row.pcon_type == 1){
        return '销售合同'
      } 
    },
    // 查询方法
    getPconsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.isLoading = true 
      this.getPconsList()
    },
    // 分页查询
    changePageSearch(val){
      this.$refs.pubPagination.currentPage = 1
      this.isLoading = true
      this.currentPage = val
      this.getPconsList()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.pcon_id)
      })
      post(pconAPI.deletePconByIds,{'pcon_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
            this.initData()
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/pcon_edit',{perm_id:permId,form_id:row.pcon_id})
    }
  }
}
</script>

<style scoped lang="scss">
</style>